import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNotifications } from '../context/NotificationContext';
import { DeleteIcon, LockIcon, NoDataIcon } from '../assets/icons/hiking';
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import wheelImage from "../assets/images/wheel.png";

export default function Cart() {
    const navigate = useNavigate();
    const { showNotification } = useNotifications();
    const [products, setProducts] = useState([]);
    const [changedProductIds, setChangedProductIds] = useState(new Set());

    const [isFormVisible, setFormVisible] = useState(false);
    const [isCartVisible, setCartVisible] = useState(true);

    useEffect(() => {
        const loadedProducts = JSON.parse(localStorage.getItem('cart')) || [];
        setProducts(loadedProducts);
    }, []);

    const handleQuantityChange = (id, newQuantity) => {
        setProducts(products.map(product => {
            if (product.id === id) {
                return { ...product, quantity: newQuantity };
            }
            return product;
        }));
        setChangedProductIds(new Set([...changedProductIds, id]));
    };

    const incrementQuantity = (id) => {
        handleQuantityChange(id, products.find(product => product.id === id).quantity + 1);
    };

    const decrementQuantity = (id) => {
        handleQuantityChange(id, Math.max(1, products.find(product => product.id === id).quantity - 1));
    };

    const updateProductInLocalStorage = (id) => {
        localStorage.setItem('cart', JSON.stringify(products));
        setChangedProductIds(new Set([...changedProductIds].filter(changedId => changedId !== id)));

        window.parent.location = window.parent.location.href;
    };

    const deleteProduct = (id) => {
        const updatedProducts = products.filter(product => product.id !== id);
        setProducts(updatedProducts);
        setChangedProductIds(new Set([...changedProductIds].filter(changedId => changedId !== id)));
        localStorage.setItem('cart', JSON.stringify(updatedProducts));

        window.parent.location = window.parent.location.href;
    };


    // summary
    useEffect(() => {
        const loadedProducts = JSON.parse(localStorage.getItem('cart')) || [];
        setProducts(loadedProducts);
    }, []);

    const subtotal = products.reduce((acc, product) => {
        const price = product.price ? parseFloat(product.price.toString().replace(/,/g, '')) : 0;
        return acc + price * product.quantity;
    }, 0);



    const shippingInformation = () => {
        setCartVisible(!isCartVisible);
        setFormVisible(!isFormVisible);
    };
    const handleButtonClick = (e) => {
        if (isFormVisible) {
            createOrderButton(e);
        } else {
            setCartVisible(false);
            setFormVisible(true);
        }
    };


    // total products 
    const getTotalProducts = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        return cart.reduce((acc, item) => acc + item.quantity, 0);
    };
    const totalProducts = getTotalProducts();


    // checkout button (create order)

    const [createOrder, setCreateOrder] = useState({
        date_created_gmt: '',
        order_id: '',
        address_index: '55555555',
        live_test: '0',
        shipping_total: '1.0',
        line_items: []
    });


    const [orderDetails, setOrderDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const createOrderButton = (e) => {
        e.preventDefault();

        setLoading(true);

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('.')[0]; //"2024-09-01T14:52:21" format
        const timestamp = currentDate.getTime().toString();
        const cartItems = JSON.parse(localStorage.getItem('cart')) || [];

        const lineItems = cartItems.map(item => ({
            sku: item.sku,
            quantity: item.quantity,
            price: item.price
        }));

        const payload = {
            ...createOrder,
            date_created_gmt: formattedDate,
            order_id: timestamp,
            line_items: lineItems,
            eid: localStorage.getItem("default"),
            endpoint: "v1/bridge/ln/create/order/standalone"
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };

        fetch('/admin/postData.php', requestOptions)
            .then(response => response.text())
            .then(responseText => {
                try {
                    const jsonMatch = responseText.match(/\{(?:[^{}]|(?:\{[^{}]*\}))*\}/);

                    if (jsonMatch) {
                        const jsonResponse = JSON.parse(jsonMatch[0]);

                        if (jsonResponse && jsonResponse.success === 1) {
                            const orderDetails = {
                                orderType: "TEST",
                                wooOrderId: timestamp,
                                addressId: "",
                                lnSalesOrderId: jsonResponse.response,
                                date: currentDate.toLocaleString()
                            };
                            setOrderDetails(orderDetails);
                            setShowSuccessPopup(true);
                        } else {
                            const Notificationpayload = {
                                response: false,
                                subject: "Order",
                                operation: "Creating",
                                context: "",
                            };
                            showNotification(Notificationpayload);
                        }
                    } else {
                        const Notificationpayload = {
                            response: false,
                            subject: "Order",
                            operation: "Creating",
                            context: "",
                        };
                        showNotification(Notificationpayload);
                    }
                } catch (error) {
                    const Notificationpayload = {
                        response: false,
                        subject: "Order",
                        operation: "Creating",
                        context: "",
                    };
                    showNotification(Notificationpayload);
                } finally {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };


    const handleClosePopup = () => {
        setShowSuccessPopup(false);
    };

    // response
    const [countdown, setCountdown] = useState(10);
    const [redirectCancelled, setRedirectCancelled] = useState(false);

    useEffect(() => {
        let timer;
        if (showSuccessPopup && !redirectCancelled) {
            setCountdown(10);
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(timer);
                        localStorage.removeItem('cart');
                        navigate('/app/home');
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showSuccessPopup, redirectCancelled]);

    const handleCancelRedirect = () => {
        setRedirectCancelled(true);
    };

    const handleClose = () => {
        localStorage.removeItem('cart');
        handleClosePopup();
        navigate('/app/home');
    };

    return (
        <div className="flex flex-col lg:flex-row justify-center items-start p-4 mx-4 lg:mx-14 mt-3">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex flex-col w-full lg:w-2/3 justify-center items-center pt-4"
                style={{ paddingTop: '150px' }}
            >
                {products.length > 0 ? (

                    <div className='flex flex-col'>

                        <div className="flex flex-col sm:flex-row items-center sm:justify-between space-y-4 sm:space-y-0">
                            <span className='font-semibold text-xl sm:text-2xl'>My Cart ({totalProducts})</span>
                            {!isCartVisible && (
                                <button onClick={shippingInformation} className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200">Edit</button>
                            )}
                        </div>

                        <hr className="w-full my-3 border-gray-300 border-1" />

                        {isCartVisible && (
                            <div className="space-y-4">
                                {products.map((product) => (
                                    <div key={product.id} className="relative flex flex-col sm:flex-row w-full sm:items-center mb-4 rounded-lg p-4" style={{ borderBottom: '1px solid #e5e7eb' }}>
                                        <img src={product.image} alt={product.title} className="w-full sm:w-36 mb-4 sm:mb-0 sm:mr-4" />

                                        <div className="flex flex-col flex-grow">
                                            <div className="flex justify-between">
                                                <span className="text-sm font-medium">{product.title}</span>
                                                <div className="cursor-pointer" onClick={() => deleteProduct(product.id)}>
                                                    <DeleteIcon />
                                                </div>
                                            </div>

                                            <span className="mt-4 sm:mt-10">ID: {product.id}</span>

                                            <div className="flex flex-col sm:flex-row justify-between items-end flex-grow mt-4 sm:mt-0">
                                                <div className="flex items-center mb-4 sm:mb-0">
                                                    <button className="px-3 py-1 border rounded" onClick={() => decrementQuantity(product.id)}>-</button>
                                                    <input
                                                        className="mx-2 px-3 py-1 border rounded text-center"
                                                        style={{ width: '64px' }}
                                                        type="text"
                                                        value={product.quantity}
                                                        onChange={(e) => handleQuantityChange(product.id, Math.max(1, parseInt(e.target.value) || 0))}
                                                    />
                                                    <button className="px-3 py-1 border rounded" onClick={() => incrementQuantity(product.id)}>+</button>
                                                    {changedProductIds.has(product.id) && <button className="px-2 py-1 bg-yellow-500 text-white rounded text-xs ml-2" onClick={() => updateProductInLocalStorage(product.id)}>Update</button>}
                                                </div>

                                                <span className="font-bold">€{product.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className='pb-5'>
                            <div className="flex flex-col sm:flex-row items-center sm:justify-between space-y-4 sm:space-y-0">
                                <span className='font-semibold text-xl sm:text-2xl'>Shipping Information</span>
                                {!isFormVisible && (
                                    <button onClick={shippingInformation} className="font-semibold text-black py-2 px-4 rounded border border-black bg-gray-100 hover:bg-gray-200">Edit</button>
                                )}
                            </div>
                            <hr className="w-full my-3 border-gray-300 border-1" />

                            {isFormVisible && (
                                <form className="space-y-4">
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                Name
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200" id="grid-first-name" type="text" />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Last Name
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-last-name" type="text" />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address">
                                                Address 1
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-address" type="text" />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-address2">
                                                Address 2
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-address2" type="text" />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-city">
                                                City
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-city" type="text" />
                                        </div>
                                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                State / Province
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-state" type="text" />
                                        </div>
                                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-zip">
                                                Postal / Zip Code
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-zip" type="text" />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                                Country
                                            </label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:bg-gray-200" id="grid-country" type="text" />
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>

                        <div className='pb-5'>
                            <div className="flex flex-col sm:flex-row justify-between items-center pb-1">
                                <span className='font-semibold text-xl sm:text-2xl'>Billing & Payment</span>
                                <LockIcon />
                            </div>
                            <hr className="w-full my-3 border-gray-300 border-1" />
                        </div>

                    </div>

                ) : (
                    <div className="flex flex-col items-center">
                        <span className="text-xl font-semibold">Your cart is empty.</span>
                        <NoDataIcon />
                    </div>
                )
                }



            </motion.div >

            {/* Order summary */}
            <div style={{ paddingTop: '150px' }} className="w-full lg:ml-8 lg:w-1/3 mt-6 lg:mt-0">
                <div className="py-5 bg-gray-200 p-4 rounded-lg">
                    <h2 className="font-bold text-lg mb-4">Order Summary</h2>
                    <div className="flex justify-between mb-2">
                        <span>Subtotal</span>
                        <span>€{subtotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="flex justify-between mb-2">
                        <span>Estimated Tax</span>
                        <span>€0.00</span>
                    </div>
                    <div className="flex justify-between mb-2">
                        <span>Estimated Shipping</span>
                        <span>€0.00</span>
                    </div>

                    <hr className="my-6 border-gray-300 border-1" />

                    <div className="flex justify-between mb-4 font-bold">
                        <span className="text-lg">Estimated Total</span>
                        <span className="text-xl">€{subtotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>

                    <hr className="my-6 border-gray-300 border-1" />

                    <div>
                        <button
                            className="w-full text-white py-2 rounded bg-black"
                            onClick={handleButtonClick}
                            disabled={!isFormVisible && !isCartVisible}
                        >
                            {isFormVisible ? 'Checkout' : 'Continue'}
                        </button>
                    </div>
                </div>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Dialog open={showSuccessPopup} onClose={handleClose}>
                    <DialogTitle className="p-4 text-lg font-semibold">Order Created Successfully!</DialogTitle>
                    <DialogContent className="p-4">
                        <Typography className="mb-2">
                            <span className="font-semibold">Order Type:</span> {orderDetails.orderType}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">Woo Order ID:</span> {orderDetails.wooOrderId}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">Address ID:</span> {orderDetails.addressId || ""}
                        </Typography>
                        <Typography className="mb-2">
                            <span className="font-semibold">LN Sales Order ID:</span> {orderDetails.lnSalesOrderId}
                        </Typography>
                        <Typography className="mb-4">
                            <span className="font-semibold">Date:</span> {orderDetails.date}
                        </Typography>
                        <div className="flex justify-end mt-4">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </div>
                        {!redirectCancelled && (
                            <div className="mt-6 text-center">
                                <Typography className="text-sm text-gray-600">
                                    You're going to be redirected to the home page in {countdown} seconds...
                                </Typography>
                                <Typography
                                    className="text-sm text-blue-600 underline cursor-pointer mt-2"
                                    onClick={handleCancelRedirect}
                                >
                                    Cancel redirect
                                </Typography>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>

                <div>
                    <div className="wheel-image mt-4 relative overflow-hidden rounded-t-lg h-40">
                        <img className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={wheelImage} />

                        <p className="contenedor-texto">Stock Clearance</p>
                        <p className="description-card">30% off on stock clearance</p>
                    </div>

                    <div className="bg-white px-4 py-4">
                        <p className="text-lg font-semibold mb-2">Thank you for your interest in shopping with TRC.</p>
                        <p>Here are some tips to help ensure a smooth ordering experience and to help set expectations.</p>

                        <hr className="my-6 border-gray-300 border-1" />

                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>If you are ordering certain chemicals, be aware there may be limits on how we can ship your items that will change your orders shipping time.</p>
                        </div>

                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>The same goes for customers not in the continental United States. </p>
                        </div>
                        <div className="flex items-center space-x-2 mb-4">
                            <p className='text-xs'>Other individual states, such as California, may have additional restrictions on what or how we can ship.</p>
                        </div>

                        <hr className="my-6 border-gray-300 border-1" />

                        <p className='text-center mb-4'> Please review our full shipping
                            Terms and conditions found here to learn more about how we will fulfill your order.
                        </p>

                        <button className="w-full text-black font-semibold py-2 rounded border border-black cursor-pointer" onClick={() => { navigate('tos'); }}>Terms & Conditions</button>
                    </div>
                </div>
            </div>





        </div >
    );

}
