import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from "framer-motion";
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function Customers() {

    const [options, setOptions] = useState([]);
    const [catalogs, setCatalogs] = useState(['']);


    useEffect(() => {
        const eid = localStorage.getItem("default");
        const type = "catalogs_enum";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success && data.data && data.data.response) {
                    setOptions(data.data.response);
                } else {
                    console.error('Unexpected response format:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const addCatalog = () => {
        setCatalogs([...catalogs, '']); // Add a new empty catalog
    };

    const handleCatalogChange = (index, value) => {
        const updatedCatalogs = [...catalogs];
        updatedCatalogs[index] = value;
        setCatalogs(updatedCatalogs);
    };



    const [activeTab, setActiveTab] = React.useState("contact");


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [api, setApi] = useState('');
    const [accessId, setAccessId] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [dateOfBirth, setDateOfBirth] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [businessPartnerId, setBusinessPartnerId] = useState('');

    const [mailing_address, setMailing_address] = useState('');
    const [mailing_street1, setMailing_street1] = useState('');
    const [mailing_street2, setMailing_street2] = useState('');
    const [mailing_city, setMailing_city] = useState('');
    const [mailing_state, setMailing_state] = useState('');
    const [mailing_zip, setMailing_zip] = useState('');

    const [service_address, setService_address] = useState('');
    const [service_street1, setService_street1] = useState('');
    const [service_street2, setService_street2] = useState('');
    const [service_city, setService_city] = useState('');
    const [service_state, setService_state] = useState('');
    const [service_zip, setService_zip] = useState('');



    const eid = localStorage.getItem('default');

    const createCustomer = () => {
        const payload = {

            username: username,
            password: password,
            api: api,
            access_id: accessId,

            first_name: firstName,
            last_name: lastName,
            company: company,

            date_of_birth: dateOfBirth,
            customer_number: customerNumber,
            business_partner_id: businessPartnerId,

            phone: phone,
            email: email,

            mailing_address: mailing_address,
            mailing_street1: mailing_street1,
            mailing_street2: mailing_street2,
            mailing_city: mailing_city,
            mailing_state: mailing_state,
            mailing_zip: mailing_zip,

            service_address: service_address,
            service_street1: service_street1,
            service_street2: service_street2,
            service_city: service_city,
            service_state: service_state,
            service_zip: service_zip,

            eid: eid,
            endpoint: "v1/customer/add",
        };
        catalogs.forEach((catalog, index) => {
            if (catalog && catalog !== "Select") {
                payload[`catalog_${index + 1}`] = catalog;
            }
        });



        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response) {

                    setFirstName('');
                    setLastName('');
                    setCompany('');
                    setPhone('');
                    setEmail('');

                    setMailing_address('');
                    setMailing_street1('');
                    setMailing_street2('');
                    setMailing_city('');
                    setMailing_state('');
                    setMailing_zip('');

                    setService_address('');
                    setService_street1('');
                    setService_street2('');
                    setService_city('');
                    setService_state('');
                    setService_zip('');
                    setCatalogs(['']);

                } else {
                    alert("error")
                }
            });
    }




    return (
        <div className="px-10">

            <div className="flex justify-between items-center py-6 pt-10">

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex justify-center w-full min-h-screen pt-5"
                >

                    <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">
                        <div>
                            <div className="flex justify-between items-center py-6">
                                <div>
                                    <Button
                                        type="button"
                                        onClick={() => createCustomer()}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        className="bg-blue-500 text-white hover:bg-blue-600 transition-all mt-4 rounded-lg shadow-md"
                                        sx={{ padding: '8px 16px' }} // Additional MUI styling
                                    >
                                        create customer
                                    </Button>

                                </div>
                            </div>


                            <div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">username:</label>
                                    <input
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">password:</label>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">Api:</label>
                                    <input
                                        value={api}
                                        onChange={(e) => setApi(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">acces id:</label>
                                    <input
                                        value={accessId}
                                        onChange={(e) => setAccessId(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">first name:</label>
                                    <input
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">Last Name:</label>
                                    <input
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">Company:</label>
                                    <input
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">Date of Brith:</label>
                                    <input
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">customer #:</label>
                                    <input
                                        value={customerNumber}
                                        onChange={(e) => setCustomerNumber(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                                <div className="flex-1 mr-2">
                                    <label className="text-sm font-semibold">Business Partner ID:</label>
                                    <input
                                        value={businessPartnerId}
                                        onChange={(e) => setBusinessPartnerId(e.target.value)}
                                        placeholder="---"
                                        className="w-full input-style"
                                        type="text"
                                    />
                                </div>

                            </div>



                            <div className="flex justify-center mt-4 border-b border-gray-300">
                                <button
                                    className={`py-2 px-4 ${activeTab === "contact" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                                    onClick={() => setActiveTab("contact")}
                                >
                                    Contact
                                </button>
                                <button
                                    className={`py-2 px-4 ml-2 ${activeTab === "mailing" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                                    onClick={() => setActiveTab("mailing")}
                                >
                                    Mailing Address
                                </button>

                                <button
                                    className={`py-2 px-4 ml-2 ${activeTab === "service-address" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                                    onClick={() => setActiveTab("service-address")}
                                >
                                    Service Address
                                </button>


                                <button
                                    className={`py-2 px-4 ml-2 ${activeTab === "catalogs" ? "text-[#4C7021] border-b-2 border-[#4C7021]" : "text-gray-500"} rounded-t`}
                                    onClick={() => setActiveTab("catalogs")}
                                >
                                    Catalogs
                                </button>


                            </div>

                            <div className="mt-4">
                                {activeTab === "contact" && (
                                    <div>
                                        <div className="flex justify-between items-center mb-2">
                                            <div className="flex-1 mr-2">
                                                <label className="text-sm font-semibold">Phone Number:</label>
                                                <input
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    placeholder="---"
                                                    className="w-full input-style"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="flex-1 ml-2">
                                                <label className="text-sm font-semibold">Email:</label>
                                                <input
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="---"
                                                    className="w-full input-style"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeTab === "mailing" && (
                                    <div>
                                        <label className="text-sm font-semibold">Mailing Adress Nickname:</label>
                                        <input
                                            value={mailing_address}
                                            onChange={(e) => setMailing_address(e.target.value)}
                                            placeholder="---"
                                            className="mb-2 w-full input-style"
                                            type="text"
                                        />

                                        <div>
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex-1 mr-2">
                                                    <label className="text-sm font-semibold">Street 1:</label>
                                                    <input
                                                        value={mailing_street1}
                                                        onChange={(e) => setMailing_street1(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="flex-1 ml-2">
                                                    <label className="text-sm font-semibold">Street 2:</label>
                                                    <input
                                                        value={mailing_street2}
                                                        onChange={(e) => setMailing_street2(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex-1 mr-2">
                                                    <label className="text-sm font-semibold">City:</label>
                                                    <input
                                                        value={mailing_city}
                                                        onChange={(e) => setMailing_city(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="flex-1 ml-2">
                                                    <label className="text-sm font-semibold">State:</label>

                                                    <select
                                                        value={mailing_state}
                                                        onChange={(e) => setMailing_state(e.target.value)}
                                                        className="mb-2 w-full input-style cursor-pointer">
                                                        <option>---</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <label className="text-sm font-semibold">Zip:</label>
                                        <input
                                            value={mailing_zip}
                                            onChange={(e) => setMailing_zip(e.target.value)}
                                            placeholder="---"
                                            className="mb-2 w-full input-style"
                                            type="text"
                                        />
                                    </div>
                                )}

                                {activeTab === "service-address" && (
                                    <div>
                                        <label className="text-sm font-semibold">Service Address Nickname:</label>
                                        <input
                                            value={service_address}
                                            onChange={(e) => setService_address(e.target.value)}
                                            placeholder="---"
                                            className="mb-2 w-full input-style"
                                            type="text"
                                        />

                                        <div>
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex-1 mr-2">
                                                    <label className="text-sm font-semibold">Street 1:</label>
                                                    <input
                                                        value={service_street1}
                                                        onChange={(e) => setService_street1(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="flex-1 ml-2">
                                                    <label className="text-sm font-semibold">Street 2:</label>
                                                    <input
                                                        value={service_street2}
                                                        onChange={(e) => setService_street2(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex justify-between items-center mb-2">
                                                <div className="flex-1 mr-2">
                                                    <label className="text-sm font-semibold">City:</label>
                                                    <input
                                                        value={service_city}
                                                        onChange={(e) => setService_city(e.target.value)}
                                                        placeholder="---"
                                                        className="w-full input-style"
                                                        type="text"
                                                    />
                                                </div>

                                                <div className="flex-1 ml-2">
                                                    <label className="text-sm font-semibold">State:</label>
                                                    <select
                                                        value={service_state}
                                                        onChange={(e) => setService_state(e.target.value)}
                                                        className="mb-2 w-full input-style cursor-pointer">
                                                        <option>---</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <label className="text-sm font-semibold">Zip:</label>
                                        <input
                                            value={service_zip}
                                            onChange={(e) => setService_zip(e.target.value)}
                                            placeholder="---"
                                            className="mb-2 w-full input-style"
                                            type="text"
                                        />
                                    </div>
                                )}

                                {activeTab === "catalogs" && (
                                    <div>
                                        {catalogs.map((catalog, index) => (
                                            <div key={index} style={{ marginBottom: '10px' }}>
                                                <label>Catalog {index + 1}</label>
                                                <select
                                                    value={catalog}
                                                    onChange={(e) => handleCatalogChange(index, e.target.value)}
                                                >
                                                    {options.map((option, idx) => (
                                                        <option key={idx} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        ))}
                                        <span
                                            onClick={addCatalog}
                                            style={{
                                                color: '#3b82f6',
                                                cursor: 'pointer',
                                                display: 'inline-block',
                                                padding: '8px 16px',
                                                transition: 'color 0.2s',
                                            }}
                                            className="hover:text-blue-600 mt-4"
                                        >
                                            Add Catalog
                                        </span>

                                    </div>
                                )}



                            </div>

                        </div>
                    </div>
                </motion.div>


            </div>



        </div>
    );

}


export default Customers;


