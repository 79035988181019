import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';


import Header from '../pages/settings/header';
import CategoryOne from '../pages/settings/categoryOne';
import CategoryTwo from './settings/categoryTwo';
import CategoryThree from './settings/categoryThree';
import CategoryFour from './settings/categoryFour';
import Orders from './orders';
import Customers from './customers'

export function Payers() {
    const [activeSection, setActiveSection] = useState('Header');

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };


    const linkStyle = (section) => {
        return section === activeSection
            ? { borderColor: '#EC2842', color: '#142830' }
            : { borderColor: 'transparent', color: 'gray' };
    };

    const renderContent = () => {
        switch (activeSection) {
            case 'Header':
                return <Header />;
            case 'Category_1':
                return <CategoryOne />;
            case 'Category_2':
                return <CategoryTwo />;
            case 'Category_3':
                return <CategoryThree />;
            case 'Category_4':
                return <CategoryFour />
            case 'Orders':
                return <Orders />
            case 'Customers':
                return < Customers />;
            default:
                return null;
        }
    };


    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='pt-20'>

            <div>
                <nav className="border-b border-gray-200 pt-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="-mb-px flex space-x-8">
                            {['Header', 'Category_1', 'Category_2', 'Category_3', 'Category_4', 'Orders', 'Customers'].map((section) => (
                                <a
                                    key={section}
                                    href="#"
                                    className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm nav-link ${section === activeSection ? 'active' : ''}`}
                                    style={linkStyle(section)}
                                    onClick={() => handleSectionClick(section)}
                                    aria-current={section === activeSection ? 'page' : undefined}
                                >
                                    {section}
                                </a>
                            ))}
                        </div>
                    </div>
                </nav>
                <div className="section-content">
                    {renderContent()}
                </div>
            </div>
        </motion.div>
    );




}
