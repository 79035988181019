import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { SavedIcon, CheckIcon, FullViewIcon, SaveIcon, NoDataIcon } from '../assets/icons/hiking';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { useCart } from '../context/CartContext';
import { Badge } from 'antd';
import Backdrop from '@mui/material/Backdrop';
import { Breadcrumb } from 'antd';
import { SearchContext } from '../context/SearchContext';
import Sidebar from '../components/productSidebar';
import CircularProgress from '@mui/material/CircularProgress';

export default function Products() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const { searchTerm } = useContext(SearchContext);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

    useEffect(() => {
        const updateQueryParams = () => {
            setQueryParams(new URLSearchParams(window.location.search));
        };

        window.addEventListener('popstate', updateQueryParams);
        window.addEventListener('urlChange', updateQueryParams);

        return () => {
            window.removeEventListener('popstate', updateQueryParams);
            window.removeEventListener('urlChange', updateQueryParams);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    const categoryString = queryParams.get('category');
    const tagString = queryParams.get('tag');

    const categoryArray = categoryString ? categoryString.split(",") : [];
    const tagArray = tagString ? tagString.split(",") : [];

    useEffect(() => {
        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");
        const type = "product";
        const customerCatalogs = JSON.parse(localStorage.getItem("customer_catalogs")) || [];

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        setIsLoadingProducts(true);

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&search=" + searchTerm + "&nonce=" + nonce + "&filter=";

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    let filteredProducts = data.data.response;

                    const normalizedCustomerCatalogs = customerCatalogs.map(catalog => catalog.replace(/\s+/g, '').toLowerCase());
                    console.log("Normalized Customer Catalogs:", normalizedCustomerCatalogs);

                    filteredProducts = filteredProducts.filter(product => {
                        const productCatalogs = product.catalog_list
                            .replace(/[\[\]]/g, '')
                            .split(',')
                            .map(catalog => catalog.replace(/\s+/g, '').toLowerCase());

                        const matchFound = productCatalogs.some(catalog => normalizedCustomerCatalogs.includes(catalog));

                        return matchFound;
                    });

                    if (categoryArray.length > 0) {
                        filteredProducts = filteredProducts.filter(product =>
                            categoryArray.includes(product.identifiers.category_id)
                        );
                    }

                    if (tagArray.length > 0) {
                        filteredProducts = filteredProducts.filter(product =>
                            product.product.tags && tagArray.some(tag => product.product.tags.includes(tag))
                        );
                    }

                    setProducts(filteredProducts);
                } else {
                    setProducts([]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setProducts([]);
            })
            .finally(() => {
                setIsLoadingProducts(false);
            });
    }, [queryParams, searchTerm]);


    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});

    const openModal = (product) => {
        setSelectedProduct({ ...product, initialCount: 1 });
        setCount(1);
        setModalOpen(true);
    };


    const handleClosePopup = () => {
        setModalOpen(false);
        setSelectedProduct(null);
    };

    //counter 
    const [count, setCount] = useState(1);

    const inputCounter = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0) {
            setCount(value);
        }
    };
    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decrement = () => {
        setCount(prevCount => (prevCount > 1 ? prevCount - 1 : 1));
    };

    // product add to cart
    const handleAddToCart = (product) => {
        let cart = [...cartProducts];
        const productIndex = cart.findIndex((item) => item.id === product.id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += 1;
        } else {
            cart.push({ ...product, quantity: 1 });
        }

        setCartProducts(cart);
    };

    const addToCartButton = (product) => {

        fetchProductPrice(product.item_number)
            .then(price => {
                if (price !== null) {
                    const productWithPrice = {
                        ...product,
                        price: price
                    };

                    openModal(productWithPrice);
                    handleAddToCart(productWithPrice);
                } else {
                    console.error("Failed to retrieve product price");
                }
            })
            .catch(error => {
                console.error("error getting price");
            });
    };

    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);

    function fetchProductPrice(item_number) {
        const customer_number = localStorage.getItem('business_partner_id');
        const eid = localStorage.getItem('default');

        const payload = {
            eid: eid,
            endpoint: 'v1/get/price',
            business_partner_id: customer_number,
            item_number: item_number
        };

        setPriceLookUpLoading(true);

        return fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setPriceLookUpLoading(false);
                if (data.success === 1 && data.response && data.response.data) {
                    console.log("Price of the product from LN:", data.response.data.netAmount);
                    return data.response.data.netAmount;
                } else {
                    alert("No price found");
                    return null;
                }
            })
            .catch(() => {
                setPriceLookUpLoading(false);
                alert("Something went wrong");
                return null;
            });
    }


    const updateQuantity = (productId, newCount) => {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        const productIndex = cart.findIndex((item) => item.id === productId);

        if (productIndex !== -1) {
            cart[productIndex].quantity = newCount;
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        setSelectedProduct(prev => ({ ...prev, initialCount: newCount }));

        window.parent.location = window.parent.location.href;
    };


    //save product
    const [savedProducts, setSavedProducts] = useState([]);

    useEffect(() => {
        const products = JSON.parse(localStorage.getItem('savedProducts')) || [];
        setSavedProducts(products);
    }, []);

    function saveOrRemoveProductFromLocalStorage(product) {
        let products = [...savedProducts];
        const productIndex = products.findIndex((item) => item.id === product.id);
        let isAdding = productIndex === -1;

        if (isAdding) {
            products.push(product);
        } else {
            products.splice(productIndex, 1);
        }

        localStorage.setItem('savedProducts', JSON.stringify(products));
        setSavedProducts(products);
        return isAdding;
    }



    function isProductSaved(productId) {
        return savedProducts.some(product => product.id === productId);
    }

    const handleProductSave = (product) => {
        fetchProductPrice(product.item_number)
            .then(price => {
                if (price !== null) {

                    const productWithPrice = {
                        ...product,
                        price: price
                    };

                    saveOrRemoveProductFromLocalStorage(productWithPrice);
                } else {
                    console.error("Failed to retrieve product price for saving");
                }
            })
            .catch(error => {
                console.error("Error fetching price for saving product:", error);
            });
    };

    function decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    function AddToCartPopup({ product, onClose, count, increment, decrement, inputCounter, updateQuantity }) {
        const decodedTitle = decodeHtml(product.title);
        const decodedDescription = decodeHtml(product.Description);
        const popupRef = useRef(null);

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };


        return (
            <div
                className="fixed z-10 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
                onClick={handleOutsideClick}
            >
                <div ref={popupRef} className="bg-white p-5 rounded-lg shadow-lg w-full max-w-4xl mx-4 md:mx-6 lg:mx-8">
                    <div className="py-4 flex items-center justify-center p-4 bg-green-100">
                        <CheckIcon className="w-6 h-6 md:w-8 md:h-8" />
                        <p className="font-bold text-green-600 ml-2 text-sm md:text-base lg:text-lg">Product successfully added to your cart!</p>
                    </div>

                    <div className="flex flex-col md:flex-row px-4">
                        <img
                            src={product.image}
                            className="mb-4 md:mb-0 md:mr-4 max-w-full md:max-w-xs h-auto"
                            alt={product.title}
                        />

                        <div className="flex-1">
                            <p className="text-lg font-semibold mb-2 text-center md:text-left">{decodedTitle}</p>
                            <p className="text-base mb-4 text-center md:text-left">{decodedDescription}</p>

                            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                <div className="flex items-center mb-4 md:mb-0">
                                    <button className="px-3 py-1 border rounded" onClick={decrement}>-</button>
                                    <input
                                        className="mx-2 px-3 py-1 border rounded text-center"
                                        style={{ width: '64px' }}
                                        type="text"
                                        value={count}
                                        onChange={inputCounter}
                                    />
                                    <button className="px-3 py-1 border rounded" onClick={increment}>+</button>

                                    {count !== product.initialCount && (
                                        <button
                                            className="ml-2 px-2 py-1 bg-yellow-500 text-white rounded text-xs md:text-sm"
                                            onClick={() => updateQuantity(product.id, count)}
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                                <p className="font-bold text-center md:text-left">€{product.price}</p>
                            </div>

                            <hr className="my-6 border-gray-200 border-2" />

                            <div className="flex justify-between items-center">
                                <p className="font-bold">Total</p>
                                <p className="font-bold">€{product.price * count}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row p-4">
                        <button className="py-2 px-4 font-semibold bg-black text-white rounded flex-1 mb-2 md:mb-0 md:mr-2" onClick={onClose}>Continue Shopping</button>
                        <button
                            className="py-2 px-4 font-semibold bg-red-500 text-white rounded hover:bg-red-700 flex-1"
                            onClick={() => (window.location.href = 'https://one.trc4r.dev/app/cart')}
                        >
                            View Cart & Checkout
                        </button>

                    </div>
                </div>
            </div>

        );
    }


    const { cartProducts, setCartProducts } = useCart();
    const isProductInCart = (productId) => {
        return cartProducts.some(item => item.id === productId);
    };
    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartProducts(cart);
    }, []);


    function handleButtonClick(item_number, productSKU) {
        window.location.href = `/app/product?sku=${productSKU}`;
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ paddingTop: '120px' }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={priceLookUpLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="py-6">

                <div className=" py-2 pl-8">
                    <div>
                        <Breadcrumb>
                            <Breadcrumb.Item><a href="home">Home</a></Breadcrumb.Item>
                            <Breadcrumb.Item>Products</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <hr className="my-2 border-gray-200 border-1" />


                <div className="flex">

                    <Sidebar />

                    {isLoadingProducts ? (
                        <Grid container spacing={4}>
                            {Array.from(new Array(20)).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <Box sx={{ width: '100%', padding: 2 }}>
                                        <Skeleton variant="rectangular" width="100%" height={300} />
                                        <Box sx={{ pt: 0.5 }}>
                                            <Skeleton />
                                            <Skeleton width="60%" />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Skeleton width="15%" />
                                                <Skeleton width="15%" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : products.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mr-5 w-full">
                            {Array.isArray(products) && products.map((product) => {
                                const productImage = product.images && product.images.images_primary;
                                const productTitle = product.product && decodeHtml(product.product.title);
                                const productShortDescription = product.product && decodeHtml(product.product.short_description);
                                const productPrice = product.properties && product.properties.use_price;
                                const productSKU = product.identifiers && product.identifiers.sku;
                                const item_product = product.ln && product.ln.item_number;

                                const isInCart = isProductInCart(product.id);

                                return (
                                    <div key={product.id} style={{ textAlign: 'center', position: 'relative', width: '264px', margin: 'auto' }}>
                                        {isInCart && (
                                            <Badge.Ribbon text="Added To Cart" color="red" style={{ zIndex: 1, width: 'auto', height: 'auto' }} />
                                        )}
                                        <div
                                            className="blur-on-hover relative"
                                            style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}
                                        >
                                            <img src={productImage} alt={productTitle} style={{ maxWidth: '220px' }} />

                                            <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', boxSizing: 'border-box' }}>
                                                <div style={{ borderTop: '1px solid #ccc', width: '100%', display: 'flex', justifyContent: 'space-between', position: 'relative', paddingTop: '5px' }}>
                                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>{productSKU}</span>
                                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>{item_product}</span>
                                                    <div style={{ position: 'absolute', top: '0', bottom: '0', left: '50%', width: '1px', backgroundColor: '#ccc', transform: 'translateX(-50%)' }}></div>
                                                </div>
                                            </div>

                                            <button
                                                className="add-to-cart-button font-semibold opacity-0 text-sm"
                                                style={{
                                                    position: 'absolute',
                                                    top: '40%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 2
                                                }}
                                                onClick={() => addToCartButton({
                                                    id: product.id,
                                                    title: productTitle,
                                                    Description: productShortDescription,
                                                    image: productImage,
                                                    sku: productSKU,
                                                    item_number: product.ln ? product.ln.item_number : null,
                                                    initialCount: 1
                                                })}
                                            >
                                                Add to Cart
                                            </button>


                                            <button
                                                className="add-to-cart-button font-semibold opacity-0 text-sm"
                                                style={{
                                                    position: 'absolute',
                                                    top: '55%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 2
                                                }}
                                                onClick={() => handleButtonClick(product.ln.item_number, productSKU)}
                                            >
                                                View Product
                                            </button>
                                        </div>

                                        <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                                            <span className="font-semibold text-sm block truncate">{productTitle}</span>
                                            <span className="font-medium text-sm block truncate">{productShortDescription}</span>

                                            <div className="flex justify-between items-center">
                                                {/* <span className="font-bold text-lg">€{productPrice}</span> */}
                                                <Tooltip title={isProductSaved(product.id) ? "Item saved" : "Save item"} arrow>
                                                    <div
                                                        className="cursor-pointer relative ml-auto"
                                                        onClick={() => handleProductSave({
                                                            id: product.id,
                                                            title: productTitle,
                                                            description: productShortDescription,
                                                            sku: productSKU,
                                                            image: productImage,
                                                            item_number: item_product,
                                                            price: productPrice
                                                        })}
                                                    >
                                                        {isProductSaved(product.id) ? <SavedIcon /> : <SaveIcon />}
                                                    </div>
                                                </Tooltip>
                                            </div>

                                        </div>


                                    </div>
                                );
                            })}

                            {isModalOpen && selectedProduct && (
                                <AddToCartPopup
                                    product={selectedProduct}
                                    onClose={handleClosePopup}
                                    count={count}
                                    increment={increment}
                                    decrement={decrement}
                                    inputCounter={inputCounter}
                                    updateQuantity={updateQuantity}
                                />
                            )}
                        </div>

                    ) : (
                        <div className="flex flex-col w-full items-center pt-14">
                            <div className="flex flex-col items-center">
                                <span className="text-xl font-semibold">No products.</span>
                            </div>
                            <NoDataIcon />
                        </div>
                    )}

                </div >
            </div >
        </motion.div >
    );

}

