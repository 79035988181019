import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import CodeMirror from '@uiw/react-codemirror';
import 'react-quill/dist/quill.snow.css';
import prettier from 'prettier/standalone';
import parserHtml from 'prettier/parser-html';

import { motion } from "framer-motion";
import 'react-quill/dist/quill.snow.css';


function Orders() {

    const [orders, setOrders] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});

    const fetchOrderItems = (order_number) => {
        const eid = localStorage.getItem('default');
        const type = "order_items";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&nonce=' + order_number;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setOrderDetails(data);
            })
            .catch(error => console.error('Error:', error));
    };


    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "order";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setOrders(data.data.response);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const [selectedOrder, setSelectedOrder] = useState(null);

    const fetchOrderDetails = (nonce) => {
        const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';
        const eid = localStorage.getItem('default');
        const type = "order";
        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&nonce=' + nonce;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('API Response:', data);
                if (data.success && data.data.response) {
                    setSelectedOrder(data.data.response[0]);
                    setShowPopup(true);
                }
            })
            .catch(error => {
                console.error('Error processing data:', error);
            });
    };


    const [showPopup, setShowPopup] = React.useState(false);

    function OrderDetailsPopup({ closePopup, orderData }) {
        const [activeTab, setActiveTab] = React.useState("Details");
        const order = orderData && orderData.order ? orderData.order : {};

        const { order_number = '', customer_name = '', address1 = '', city = '', state = '', zip = '', total = '', } = order;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-10">
                <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto pt-20">

                    <h2 className="text-lg font-semibold mb-4">Order Number: {order_number}</h2>

                    <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                            <label className="text-sm font-semibold">Sold to:</label>
                            <p> {customer_name}</p>
                            <p>{address1}</p>
                            <p>{city}</p>
                            <p>{state}</p>
                            <p>{zip}</p>
                        </div>

                        <div className="flex-1 ml-2">
                            <label className="text-sm font-semibold">
                                Shipped to:
                            </label>
                            <p> {customer_name}</p>
                            <p>{address1}</p>
                            <p>{city}</p>
                            <p>{state}</p>
                            <p>{zip}</p>
                        </div>
                    </div>

                    <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                            <label className="text-sm font-semibold">Shipping Comments:</label>
                        </div>

                        <div className="flex-1 ml-2">
                            <label className="text-sm font-semibold">Contact Name:</label>
                        </div>
                    </div>

                    <div className="flex  mt-4 border-b border-gray-300">
                        <button
                            className={`py-2 px-4 ${activeTab === "Details" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("Details")}
                        >
                            Details
                        </button>
                        <button
                            className={`py-2 px-4 ml-2 ${activeTab === "Shipments" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("Shipments")}
                        >
                            Shipments
                        </button>
                    </div>


                    <div className="mt-4">
                        {activeTab === "Details" && (
                            <div>


                                <div className="flex justify-between items-center mb-2">
                                    <div className="flex-1 mr-2">
                                        <label className="text-sm font-semibold">
                                            Shipped Complete =
                                            <span className="inline-block w-3 h-3 bg-customGreen ml-1 mr-4"></span>
                                            Shipped Partial =
                                            <span className="inline-block w-3 h-3 bg-yellow-200 ml-1 mr-4"></span>
                                            Not Shipped =
                                            <span className="inline-block w-3 h-3 bg-red-200 ml-1 mr-4"></span>
                                        </label>
                                    </div>
                                </div>


                                <div className="orders-list mt-4">
                                    {orderDetails && orderDetails.data && orderDetails.data.response.map((item, index) => (
                                        <div key={index} className="bg-white mb-4 divide-y divide-gray-200 border border-gray-400">
                                            <div className="bg-customGreen px-4 py-2 flex justify-between items-center">
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm">ITEM NO.</div>
                                                    <div className="font-semibold text-sm  text-gray-600">101-110-00102</div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm">DESCRIPTION</div>
                                                    <div className="font-semibold text-sm  text-gray-600"> {item.product_info.short_description}</div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm ">QTY</div>
                                                    <div className="font-semibold text-sm  text-gray-600"> <p>{item.quantity}</p> </div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm ">U/M</div>
                                                    <div className="font-semibold text-sm  text-gray-600"><p>Box</p></div>
                                                </div>
                                            </div>

                                            <div className="px-3 py-1 flex justify-between items-center">
                                                <div className="flex items-center">
                                                    <div className="font-semibold text-sm mr-2">Date Shipped:</div>
                                                    <div className="font-semibold text-sm mr-2">2023-12-08</div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="font-semibold text-sm mr-2">QTY:</div>
                                                    <div className="font-semibold text-sm mr-2">
                                                        <p className="font-semibold text-sm mr-2">3 ea</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                        )}
                        {activeTab === "Shipments" && (
                            <div>
                                <div className="flex justify-between items-center mb-2">
                                    <div className="flex-1 mr-2">
                                        <label className="text-sm font-semibold">
                                            Shipped Complete =
                                            <span className="inline-block w-3 h-3 bg-green-200 ml-1 mr-4"></span>
                                            Shipped Partial =
                                            <span className="inline-block w-3 h-3 bg-yellow-200 ml-1 mr-4"></span>
                                            Not Shipped =
                                            <span className="inline-block w-3 h-3 bg-red-200 ml-1 mr-4"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="orders-list mt-4">
                                    <div className="bg-white mb-4 divide-y divide-gray-200 border border-gray-400">
                                        <div className="bg-gray-300 px-3 py-1 flex justify-between items-center">
                                            <div className="flex items-center">
                                                <div className="font-semibold text-sm mr-2">Shipment Number:</div>
                                                <div className="font-semibold text-sm mr-2">100112370</div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="font-semibold text-sm mr-2">Invoice Number:</div>
                                                <div className="font-semibold text-sm mr-2">
                                                    <p className="text-blue-500 cursor-pointer underline">20310511</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-2 flex justify-between items-center">
                                            <div className="flex-1 flex justify-center items-center">
                                                <button
                                                    className="bg-red-600 text-white text-sm font-bold py-1 px-2 rounded"
                                                >
                                                    More
                                                </button>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm">Ship Date</div>
                                                <div className="font-semibold text-sm  text-gray-600">2023-04-28</div>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm ">Carrier:</div>
                                                <div className="font-semibold text-sm  text-gray-600"> <p>UPS</p> </div>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm ">Tracking:</div>
                                                <div className="font-semibold text-sm  text-gray-600"><p>status</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="flex justify-end mt-6">
                        <button onClick={closePopup} className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2">Close</button>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="container-tokens pt-20"
        >
            <div className="header-tokens flex justify-between">
                {/* elements on the left */}
                <div className="flex items-center">
                    <h1 className="activity-log-title mr-4">Order History</h1>
                    <input
                        type="text"
                        placeholder="Search by name"
                        className="input-tokens"
                    />
                </div>
            </div>

            <div className="orders-list mt-4">
                {orders.map(order => (
                    <div key={order.id} className="bg-white mb-4 divide-y divide-gray-200 border border-gray-400">
                        <div className="bg-gray-300 px-3 py-1 flex justify-between items-center">
                            <div className="flex items-center">
                                <div className="font-semibold text-sm mr-2">Order Number:</div>
                                <div className="font-semibold">{order.order.order_number}</div>
                            </div>
                            <div className="flex items-center">
                                <div className="font-semibold text-sm mr-2">P.O. Number:</div>
                                <div className="font-semibold"><p>{order.order.po_number}</p></div>
                            </div>
                        </div>
                        <div className="px-4 py-2 flex justify-between items-center">
                            <div className="flex-1 flex justify-center items-center">
                                <button
                                    onClick={() => {
                                        fetchOrderDetails(order.order.nonce);
                                        fetchOrderItems(order.order.order_number);
                                    }}
                                    className="bg-red-600 text-white font-bold py-2 px-4 rounded"
                                >
                                    More
                                </button>

                                {showPopup && <OrderDetailsPopup closePopup={() => setShowPopup(false)} orderData={selectedOrder} />}
                            </div>
                            <div className="flex-1 flex flex-col items-center">
                                <div className="font-semibold text-sm  text-gray-600">Order date:</div>
                                <div className="font-semibold">{order.order.created_date}</div>
                            </div>
                            <div className="flex-1 flex flex-col items-center">
                                <div className="font-semibold text-sm  text-gray-600">Promise Date:</div>
                                <div className="font-semibold"> <p>{order.order.promised_date}</p> </div>
                            </div>
                            <div className="flex-1 flex flex-col items-center">
                                <div className="font-semibold text-sm  text-gray-600">Status:</div>
                                <div className="font-semibold"><p>{order.order.status}</p></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </motion.div>
    );
}


export default Orders;


