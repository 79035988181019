import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import { SavedIcon, CheckIcon, FullViewIcon, SaveIcon } from '../../assets/icons/hiking';
import { useNavigate } from 'react-router-dom';

export default function Testing() {



    function submitText() {
        const eid = localStorage.getItem('default');

        const payload = {
            eid: eid,
            endpoint: 'v1/wysiwyg/save',
            wysiwyg_type: 'settings',
            wysiwyg_text: ""
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    alert("text updated successfully!")
                } else {
                    alert("something went wrong");
                }
            })
            .catch(() => {
                alert("something went wrong");
            });
    };


    function submitTexto() {
        const eid = localStorage.getItem('default');

        const payload = {
            eid: eid,
            endpoint: 'v1/get/price',
            business_partner_id: "320000691",
            item_number: "101-130-00024",
        };

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    alert("text updated successfully!")
                } else {
                    alert("something went wrong");
                }
            })
            .catch(() => {
                alert("something went wrong");
            });
    };


    const [formData, setFormData] = useState({
        access_id: '',
        amount: '',
        api: '',
        business_partner_id: '',
        cardholdername: '',
        catalog_1: '',
        catalog_2: '',
        catalog_3: '',
        catalog_4: '',
        catalog_5: '',
        catalog_6: '',
        catalog_7: '',
        catalog_8: '',
        catalog_9: '',
        catalog_10: '',
        city: '',
        company: '',
        customer_email: '',
        customer_number: '',
        date_of_birth: '',
        eid: 'MTY1YjI2YzBkMGI1YTc=',
        email: '',
        endpoint: 'v1/customer/add',
        first_name: '',
        last_name: '',
        mailing_address: '',
        mailing_city: '',
        mailing_state: '',
        mailing_street1: '',
        mailing_street2: '',
        mailing_zip: '',
        nonce: '',
        password: '',
        phone: '',
        service_address: '',
        service_city: '',
        service_state: '',
        service_street1: '',
        service_street2: '',
        service_zip: '',
        state: '',
        street: '',
        tax: '',
        username: '',
        zip: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('/admin/postData.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit form data');
            }

            const result = await response.json();
            console.log('Form submission successful:', result);
            // You can add additional success handling here, like redirecting or showing a message
        } catch (error) {
            console.error('Error submitting form data:', error);
            // Add error handling, like displaying an error message to the user
        }
    };




    return (
        <div
            style={{ paddingTop: '150px' }}>
            <Box component="form" sx={{ p: 4, backgroundColor: '#f9f9f9', borderRadius: 2 }}>


            </Box>



            <button onClick={submitText} className='pt-20'>
                1nd button
            </button>



            <button onClick={submitTexto} className='pt-20'>
                2nd button (get price)
            </button>




            <form onSubmit={handleSubmit}>
                {Object.keys(formData).map((field) => (
                    <div key={field}>
                        <label htmlFor={field}>{field.replace(/_/g, ' ')}</label>
                        <input
                            type="text"
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            placeholder={field}
                        />
                    </div>
                ))}
                <button type="submit">Submit</button>
            </form>



            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mr-5 w-full">
                {Array(10).fill().map((_, index) => (
                    <div key={index} style={{ textAlign: 'center', position: 'relative', width: '264px', margin: 'auto' }}>
                        <div className="blur-on-hover relative" style={{ backgroundColor: 'rgba(255, 255, 255)', width: '264px', height: '307px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                            <img src={`productImage${index + 1}.jpg`} alt={`Producto ${index + 1}`} style={{ maxWidth: '220px' }} />
                            <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px', boxSizing: 'border-box' }}>
                                <div style={{ borderTop: '1px solid #ccc', width: '100%', display: 'flex', justifyContent: 'space-between', position: 'relative', paddingTop: '5px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>100-20-3429</span>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', padding: '0 5px' }}>item 200</span>
                                </div>
                            </div>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                                Add to Cart
                            </button>
                            <button className="add-to-cart-button font-semibold opacity-0 text-sm" style={{ position: 'absolute', top: '55%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }} onClick={() => window.location.href = `/app/product?sku=SKU-00${index + 1}`}>
                                View Product
                            </button>
                        </div>

                        <div style={{ textAlign: 'left', marginTop: '10px', width: '264px', margin: 'auto' }}>
                            <span className="font-semibold text-sm block truncate">Product Title</span>
                            <span className="font-medium text-sm block truncate">Short Description</span>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span style={{ fontWeight: '700', fontSize: '20px' }}>€200.00</span>
                                <div className="cursor-pointer" style={{ position: 'relative' }}>
                                    <SaveIcon />
                                    {/* {
                                        savedNotificationProductId === product.id && (
                                            <div style={{ position: 'absolute', top: '-30px', right: '0px', backgroundColor: 'black', color: 'white', padding: '2px 4px', borderRadius: '5px', zIndex: '50', fontSize: '12px' }}>
                                                Saved
                                            </div>
                                        )
                                    } */}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}
